
.h2bg{
  background-image: linear-gradient(117deg,#0547ff -84%,#d683ff 125%);
}

.btn1 {
  @apply py-3 font-medium focus:outline-none text-violet-800 hover:underline
}
.btn2 {
  @apply px-8 py-3 flex justify-center items-center space-x-3 rounded-md border font-medium focus:outline-none focus:ring-2 focus:ring-violet-300 dark:focus:ring-violet-900 bg-violet-100 text-violet-800 border-violet-200 hover:bg-violet-50 dark:text-violet-300 dark:border-violet-500/30 dark:hover:bg-violet-500/30 dark:bg-violet-500/20
}
.content {
  @apply flex items-center mb-44 space-x-4 flex-col space-y-8 sm:flex-row
}
